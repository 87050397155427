html, body, #root {
    height: 100%
}

#root {
    display:flex;
    flex-direction: column;
}

.section {
    flex: 1 0 auto;
}

footer {
    flex-shrink: 0;
}

.feature-blocks {
    margin-top: -100px;
    padding-left: 70px;
    padding-top: 80px;
    padding-right: 70px;
}

.feature-icon {
    padding: 5px;
}

.announcement {
    padding: 15px;
}

.footerLink {
    color: #ffbc3b;
}

.footerLink:hover {
    color: white;
}

.announcementLink {
    color: #1e1e4b;
}

.announcementLink:hover {
    color: white;
}